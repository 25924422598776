<template>
  <validation-observer ref="validation" v-slot="{ invalid }">
    <m-save-profile-button :disabled="invalid || saveButtonDisabled" @onButtonClick="save" />
    <m-settings-panel :description="$t('profile.personal.description')" :title="$t('profile.personal.title')">
      <m-image-picker v-model="image" :label="$t('profile.personal.image')" :user="me" />
      <m-text-field
        v-model="firstName"
        :label="$t('profile.personal.firstName')"
        sentry-tag="profile.input.firstName"
        validation-rules="required|max:150"
      />
      <m-text-field
        v-model="lastName"
        :label="$t('profile.personal.lastName')"
        sentry-tag="profile.input.lastName"
        validation-rules="required|max:150"
      />
      <m-text-field
        v-model="email"
        :label="$t('profile.personal.email')"
        readonly
        sentry-tag="profile.input.email"
        validation-rules="required|email"
      />
      <m-gender v-model="gender" :label="$t('profile.gender.title')" sentry-tag="profile.input.gender" />
      <m-select
        v-if="hasCustomCompanies"
        v-model="company"
        :items="customCompanies"
        :label="$t('profile.personal.company')"
      />
      <m-text-field
        v-else
        v-model="company"
        :label="$t('profile.personal.company')"
        sentry-tag="profile.input.company"
        validation-rules="max:150"
      />
      <m-location v-model="location" :label="$t('profile.personal.location')" sentry-tag="profile.input.location" />
      <m-select v-if="hasCustomUnits" v-model="unit" :items="customUnits" :label="$t('profile.personal.unit')" />
      <m-select
        v-if="hasCustomPositions"
        v-model="jobTitle"
        :items="customPositions"
        :label="$t('profile.personal.jobTitle')"
      />
      <m-text-field
        v-else
        v-model="jobTitle"
        :label="$t('profile.personal.jobTitle')"
        sentry-tag="profile.input.jobTitle"
        validation-rules="max:150"
      />
      <component
        :is="field.component"
        v-for="(field, index) in customFields"
        :key="`custom-${index}`"
        v-model="custom[field.name]"
        :i18n-path="field.i18nPath"
        :items="field.items"
        :label="$t(`profile.customProfile.${field.name}.title`)"
        :validation-rules="field.validation"
        v-bind="field.props"
      />
      <m-language v-model="languages" :label="$t('profile.personal.languages')" sentry-tag="profile.input.languages" />
      <m-time-zone-picker v-if="!forceTimeZone" v-model="tz" :label="$t('profile.tz')" />
    </m-settings-panel>

    <m-settings-panel :description="$t('profile.about.description')" :title="$t('profile.about.title')">
      <m-text-area v-model="about" :label="$t('profile.about.about')" sentry-tag="profile.textarea.about" />
    </m-settings-panel>

    <m-settings-panel v-if="special" title="Manager">
      <m-autocomplete-users-pure v-model="manager" :items="managers" max-width="800px" />
    </m-settings-panel>

    <m-settings-panel :description="$t('profile.delete.description')" :title="$t('profile.delete.title')">
      <p>{{ $t('profile.delete.action.description') }}</p>
      <div>
        <a class="error--text" @click="deleteUserDialog = true">
          {{ $t('profile.delete.action.deleteProfile') }}
        </a>
      </div>
    </m-settings-panel>
    <m-delete-member-dialog v-model="deleteUserDialog" @member-deleted="deleteUserDialog = false" />
    <m-leave-dialog :value="showDialog" @onCancel="closeDialog" @onDiscard="leavePage" @onSave="saveAndLeave" />
  </validation-observer>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { LoadManagers, SaveUserProfile, UpdateUserImage, UsersState } from '@/store/users';
import {
  MGender,
  MImagePicker,
  MLanguage,
  MLocation,
  MSelect,
  MTextArea,
  MTextField,
  MSelectMultiple,
  MTimeZonePicker,
} from '@/components/Inputs';
import { MDeleteMemberDialog } from '@/components/Dialogs/MDeleteMemeberDialog';
import { MSettingsPanel } from '@/components/Settings/MSettingsPanel';
import MSaveProfileButton from '../MSaveProfileButton';
import { ValidationObserver } from 'vee-validate';
import { MLeaveDialog } from '@/components/Dialogs';
import { haveDifferentElements } from '@/utils/array';
import { MAutocompleteUsersPure } from '@/components/Autocomplete';
import { CustomField, FieldType, ITag, ITenant } from '@mentessa/types';
import { IUser } from '@mentessa/types';

function getFieldComponent(field: CustomField) {
  switch (field.type) {
    case FieldType.SelectOne:
      return MSelect;
    case FieldType.SelectMultiple:
      return MSelectMultiple;
    case FieldType.ShortText:
      return MTextField;
    case FieldType.LongText:
      return MTextArea;
    case FieldType.URL:
      return MTextField;
    case FieldType.Location:
      return MLocation;
    default:
      console.log(`Unknown field type: ${field.type}`, field);
      return undefined;
  }
}

export default Vue.extend({
  name: 'm-profile-personal',
  components: {
    MAutocompleteUsersPure,
    MSelect,
    MGender,
    MLeaveDialog,
    MSaveProfileButton,
    ValidationObserver,
    MDeleteMemberDialog,
    MTextArea,
    MImagePicker,
    MSettingsPanel,
    MLocation,
    MTimeZonePicker,
    MTextField,
    MLanguage,
  },
  data: () => ({
    image: undefined,
    firstName: '',
    lastName: '',
    email: '',
    gender: undefined,
    company: '',
    unit: undefined as string,
    jobTitle: '',
    location: '',
    languages: [],
    about: '',
    tz: undefined,
    deleteUserDialog: false,
    loading: false,
    initialTz: undefined,
    showDialog: false,
    to: null,
    managers: [],
    manager: undefined,
    custom: {},
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TenantState>('tenant', {
      forceTimeZone: (state: TenantState) => state.tenant?.attributes?.forceTenantTimeZone ?? false,
      customPositions: (state: TenantState) => state.tenant?.attributes?.positions ?? [],
      customUnits: (state: TenantState) => state.tenant?.attributes?.units ?? [],
      customCompanies: (state: TenantState) => state.tenant?.attributes?.companies ?? [],
      special: (state: TenantState) => state.tenant?.attributes?.special ?? false,
      tenant: (state: TenantState): ITenant => state.tenant,
    }),
    hasCustomPositions() {
      return this.customPositions?.length > 0;
    },
    hasCustomUnits() {
      return this.customUnits?.length > 0;
    },
    hasCustomCompanies() {
      return this.customCompanies?.length > 0;
    },
    customFields() {
      const fields = [];
      this.tenant?.attributes.customProfileFields?.forEach((field: CustomField) => {
        if (field.hide !== true) {
          const fieldComponent = {
            name: field.name,
            attribute: field.name,
            component: getFieldComponent(field),
            type: field.type,
            items: field.items,
            label: field.label,
            i18nPath: `profile.custom.${field.name}.items`,
            validation: field.validation,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props: {} as Record<string, any>,
          };

          if (field.multiple) {
            fieldComponent.props.multiple = true;
          }

          fields.push(fieldComponent);
        }
      });
      return fields;
    },
    hasCustomFields() {
      return this.customFields.length > 0;
    },
    isChanged() {
      const customFieldChanged = (field): boolean => {
        if (field.type === FieldType.SelectOne) {
          return this.custom[field.name] !== this.me.attributes.custom?.[field.name];
        } else if (field.type === FieldType.SelectMultiple || field.type === FieldType.Location) {
          return haveDifferentElements(this.custom[field.name] ?? [], this.me.attributes.custom?.[field.name] ?? []);
        } else if ([FieldType.ShortText, FieldType.LongText, FieldType.URL].includes(field.type)) {
          return (this.custom[field.name] ?? '') !== (this.me.attributes.custom?.[field.name] ?? '');
        } else {
          console.log(`Unknown field type: ${field.type}`);
          return false;
        }
      };
      return (
        this.image !== this.me.identity.attributes.image ||
        this.firstName !== this.me.identity.attributes.firstName ||
        this.lastName !== this.me.identity.attributes.lastName ||
        this.email !== this.me.identity.attributes.email ||
        this.gender !== this.me.identity.attributes.gender ||
        this.company !== this.me.attributes.company ||
        this.jobTitle !== this.me.attributes.jobTitle ||
        this.location !== this.me.attributes.location ||
        this.about !== this.me.attributes.about ||
        haveDifferentElements(this.languages, this.me.attributes.languages ?? []) ||
        this.tz !== this.initialTz ||
        this.unit !== this.me.attributes.unit ||
        this.manager?.id !== this.me.manager?.id ||
        Object.keys(this.customFields).some((field) => customFieldChanged(this.customFields[field]))
      );
    },
    saveButtonDisabled() {
      return this.loading || (!this.isChanged && this.me.complete);
    },
  },
  watch: {
    me() {
      this.updatePersonalData();
    },
  },
  async mounted() {
    if (this.special) {
      const managers: Array<IUser> = await this.$store.dispatch(new LoadManagers());
      if (this.me.manager && !managers.find((manager) => manager.id === this.me.manager.id)) {
        managers.push(this.me.manager);
      }
      this.managers = managers ?? [];
    }
    this.updatePersonalData();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isChanged || this.to) {
      next();
    } else {
      this.to = to;
      this.showDialog = true;
    }
  },
  methods: {
    async save() {
      try {
        this.loading = true;
        await this.updateImage();
        await this.saveUserData();
      } finally {
        this.loading = false;
      }
    },
    async leavePage() {
      this.showDialog = false;
      await this.$router.push(this.to);
    },
    closeDialog() {
      this.showDialog = false;
      this.to = null;
    },
    async saveAndLeave() {
      await this.save();
      await this.leavePage();
    },
    async saveUserData() {
      const dto = {
        attributes: {
          company: this.company,
          jobTitle: this.jobTitle,
          location: this.location,
          languages: this.languages,
          about: this.about,
          unit: this.unit,
          custom: this.custom,
        },
        identity: {
          attributes: {
            image: this.image,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            gender: this.gender,
          },
        },
      };

      if (this.special) {
        dto['manager'] = this.manager ? { id: this.manager.id } : null;
      }

      if (this.tz) {
        dto['tz'] = this.tz;
      }
      await this.$store.dispatch(new SaveUserProfile(dto));
    },
    async updateImage() {
      if (this.image !== this.me.identity.attributes.image) {
        await this.$store.dispatch(new UpdateUserImage(this.image));
      }
    },
    updatePersonalData() {
      this.manager = this.me.manager;
      this.image = this.me.identity.attributes.image;
      this.firstName = this.me.identity.attributes.firstName;
      this.lastName = this.me.identity.attributes.lastName;
      this.email = this.me.identity.attributes.email;
      this.gender = this.me.identity.attributes.gender;
      this.company = this.me.attributes.company;
      this.unit = this.me.attributes.unit;
      this.jobTitle = this.me.attributes.jobTitle;
      this.location = this.me.attributes.location;
      this.languages = this.me.attributes.languages ?? [];
      this.about = this.me.attributes.about;
      if (this.forceTimeZone) {
        this.tz = this.forcedTz;
      } else {
        this.tz = this.me.tz ?? this.me.attributes.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'CET';
      }
      this.initialTz = this.tz;
      const fields = {};
      this.tenant?.attributes.customProfileFields?.forEach((field) => {
        fields[field.name] = this.me.attributes.custom?.[field.name];
      });
      this.custom = fields;
    },
  },
});
</script>
